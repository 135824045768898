/* Profile.css */
.optional-info {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}

.optional-info h3 {
  margin-bottom: 20px;
}

.optional-info label {
  display: block;
  margin-bottom: 15px;
}

.optional-info input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.optional-info button {
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.optional-info button:hover {
  background-color: #0056b3;
}
