.poll-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 10px 20px;
  border: 2px solid #007bff;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  margin: 10px 0;
  background-color: #f6f6f6;
}

.poll-container h4 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.poll-container h5 {
  font-size: 1.1em;
  margin-top: 10px;
  margin-bottom: 10px;
}

.poll-container input[type="radio"] {
  margin-right: 10px;
}

.poll-container button {
  padding: 5px 10px;
  font-size: 1em;
  border: 2px solid #007bff;
  border-radius: 5px;
  transition: all 0.3s ease;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  margin-top: 10px;
}

.poll-container button:hover {
  background-color: #0056b3;
}

.poll-container div {
  margin-bottom: 5px;
}

.poll-container h5 {
  font-size: 1.1em;
  margin-top: 15px;
  margin-bottom: 15px;
  color: #007bff; /* Makes the results heading stand out a bit more */
}

/* Makes the individual results easier to read */
.poll-container div[key] {
  color: #333; /* Darker text color */
  font-size: 1.1em;
  margin-bottom: 8px; /* Adds space between each result */
}

/* Adds space above the thank-you message */
.poll-container > div:last-child {
  margin-top: 20px;
}
