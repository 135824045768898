/* ChatGPTForm styling */
.chatGPTForm {
  border: 1px solid #e0e0e0;
  padding: 16px;
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  border-radius: 8px;
  font-family: "Roboto", sans-serif;
}

.chatGPTForm:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.chatGPTForm textarea,
.chatGPTForm button {
  margin-top: 12px;
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
}

.chatGPTForm button {
  cursor: pointer;
  background-color: #1976d2;
  color: #ffffff;
  border: none;
}

/* Modal styling */
.messageModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure modal is above other elements */
}

.modalContent {
  background-color: #ffffff;
  padding: 24px;
  border-radius: 8px;
  width: 80%; /* Takes 80% of the viewport width. Adjust if needed */

  max-width: 500px; /* Maximum width for larger screens */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modalContent textarea,
.modalContent button {
  margin-top: 12px;
  width: 80%;
  padding: 10px;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
}

.modalContent button {
  cursor: pointer;
  background-color: #1976d2;
  color: #ffffff;
  border: none;
}

.modalContent button:hover {
  background-color: #1565c0;
}
