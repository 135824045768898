@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

.rep-card {
  flex: 0 0 calc(50% - 20px);
  display: flex;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px;
  margin: 16px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: "Roboto", sans-serif;
  align-items: center;
  width: 100%;
}

.rep-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 16px;
  object-fit: cover;
}

.rep-details {
  word-wrap: break-word; /* Older browsers */
  overflow-wrap: break-word; /* Modern browsers */
}

.rep-name {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 8px;
}

.rep-party,
.rep-office,
.rep-phone {
  font-size: 16px;
  margin-bottom: 8px;
}

.rep-link {
  color: #1976d2;
  text-decoration: none;
  font-size: 16px;
}

.rep-link:hover {
  text-decoration: underline;
}

.letter-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.letter-content {
  background-color: #fff;
  padding: 20px;
  width: 80%;
  max-width: 600px;
}

/* Default styles to hide all elements during print */
@media print {
  body * {
    visibility: hidden;
  }
  .letter-modal,
  .letter-modal * {
    visibility: visible;
  }
  .letter-modal {
    position: absolute;
    left: 0;
    top: 0;
  }
}

/* This prevents unnecessary page breaks */
.no-print-break {
  page-break-inside: avoid;
}

@media print {
  .no-print {
    display: none !important;
  }
}

/* Styles for the icons */
.contact-options {
  display: flex;
  gap: 10px; /* Adjust the space between icons */
  align-items: center;
}

.contact-options button,
.contact-options a {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px; /* Adjust padding to give some space around the icon */
  border-radius: 50%; /* Makes the hover effect circular */
  transition: background-color 0.2s; /* Smooth transition for hover effects */
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-options button:hover,
.contact-options a:hover {
  background-color: rgba(0, 0, 0, 0.1); /* Gives a subtle hover effect */
}

/* Adjust the size of the icons if they're too big or small */
.contact-options svg {
  font-size: 20px; /* Adjust this value to your preference */
}

/* Media query for screens narrower than 768px (or your desired breakpoint) */
@media (max-width: 768px) {
  .rep-card {
    flex: 0 0 100%; /* 100% width for single column layout */
  }
}

@media (max-width: 768px) {
  /* This breakpoint can be adjusted */
  .rep-card {
    width: 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .rep-card {
    width: 45%; /* Adjust as needed for larger screens */
  }
}

.website-link-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-text {
  display: none;
  background-color: #f9f9f9; /* Light background color */
  color: #333; /* Darker text color for readability */
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 10; /* Higher z-index ensures it stays on top of other elements */
  left: 50%;
  transform: translateX(-50%); /* Centers the tooltip */
  top: 125%; /* Position below the tooltip */
  border: 1px solid #e0e0e0; /* subtle border */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* subtle shadow */
}

.website-link-tooltip:hover .tooltip-text {
  display: block;
}

.letter-content {
  margin: 1in;
}

@media print {
  .no-print {
    display: none !important;
  }
}
